import {
    INCREMENT,
    DECREMENT,
    FETCH_LIST_OF_CLASSES,
    SET_SCHOOL,
    SET_PREFERENCES,
    SET_CLASSES,
    SET_SECTIONS,
    SET_BLOCKS,
    GET_CLASS_BY_ID,
    SET_CLASS_COLORS,
    SET_SHADOW_SECTION,
    SET_SMART_SCHEDULER
} from './config.types';
import {getClassData, getClassNameData, getAllClassData, getListOfClasses} from '../../firebaseData';

const INITIAL_STATE = {

    count: 0,
    classColors: [
    '#5A4FA2',
    '#00ABC1',
    '#F1407E',
    '#F28C29',
    '#8800C7',
    '#77DD77',
    '#3954EC',
    ],
    smartScheduler: {
        enabled: true
    }
};

const reducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case INCREMENT:

           return {

             ...state, count: state.count + 1,

           };

        case DECREMENT:

           return {
              ...state, count: state.count - 1,

           };

        case FETCH_LIST_OF_CLASSES: {

            let classList = action.classList.classNames;

            return {
                ...state, classList: classList
            }
        }

        case SET_SCHOOL: {

            let school = action.school;

            return {
                ...state, school: school
            }
        }

        case SET_PREFERENCES: {

            let preferences = action.preferences;

            return {
                ...state, preferences: preferences
            }
        }

        case SET_CLASSES: {

            let classes = action.classes;

            return {
                ...state, classes: classes
            }
        }

        case SET_SECTIONS: {

            let sections = action.sections;

            return {
                ...state, sections: sections
            }
        }

        case SET_BLOCKS: {

            let blocks = action.blocks;

            return {
                ...state, blocks: blocks
            }
        }

        case SET_CLASS_COLORS: {

            let classColors = action.classColors;

            return {
                ...state, classColors: classColors
            }
        }

        case GET_CLASS_BY_ID: {
            let classData = action.classData;

            return {
                ...state, requestedClassData: classData
            }
        }

        case SET_SHADOW_SECTION: {

            let shadowSection = action.shadowSection;

            return {
                ...state, shadowSection: shadowSection
            }
        }

        case SET_SMART_SCHEDULER: {

            let smartScheduler = action.smartScheduler;

            return {
                ...state, smartScheduler: smartScheduler
            }
        }

        default: return state;

    }

};

export default reducer;