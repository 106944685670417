import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Autocomplete,
  TextField,
  Box,
  Popper,
  ButtonGroup,
  Button,
  Grid
} from "@mui/material";

import {
  increaseCounter,
  decreaseCounter,
  fetchListOfClasses,
  setSchool
} from "../redux/Config/config.actions";

import { useSelector, useDispatch } from "react-redux";

function Home(props) {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const options = [
    { value: 'UGA', label: 'University of Georgia', abbrev: 'UGA' },
    { value: 'KSU', label: 'Kennesaw State University', abbrev: 'KSU' },
  ];

  const handleSchoolSelected = (school) => {
    console.log(school);
    dispatch(setSchool(school));
    if (school !== null) {
      //window.location.href = school.abbrev;
      navigate(school.abbrev);
    }
  };

  return (
    <div style={{height: '100vh', maxHeight: '100vh', width: '100vw', backgroundColor: '#ECECEC', fontFamily: 'Nunito', overflow: 'hidden'}}>
      <div style={{height: '10%', display: 'flex', flexDirection: 'column', padding: '8px 16px'}}>
        <span style={{fontSize: '6vmin', fontWeight: '900', color: '#313131', fontStyle: 'italic'}}>
            Course<span style={{color: '#5A4FA2'}}>Buddy</span>
        </span>
      </div>
      <div style={{height: '100%', display: 'flex', flexDirection: 'column',  alignItems: 'center'}}>
        <div style={{display: 'flex', maxWidth: '600px', justifyContent: 'center', padding: '0px 32px'}}>
          <span style={{fontSize: '6vmin', fontWeight: '900', color: '#313131', textAlign: 'center'}}>Make a smarter class schedule <span style={{color: '#5A4FA2'}}>effortlessly.</span></span>
        </div>
        <div style={{display: 'flex', justifyContent: 'center', padding: '0px 32px', marginTop: '48px'}}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={options}
            onChange={(e, value) => handleSchoolSelected(value)}
            sx={{
              width: 300,
            }}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                <span style={{fontFamily: 'Nunito', fontWeight: 'bold'}}>{option.label}</span>
              </Box>
            )}
            renderInput={(params) => 
                <TextField
                  sx={{
                    '& .MuiInputBase-root': {
                      borderRadius: '100px',
                      fontFamily: 'Nunito',
                      fontWeight: 'bold'
                    },
                    '& .MuiInputBase-input': {
                      fontFamily: 'Nunito',
                      fontWeight: 'bold',
                      paddingLeft: '8px',
                    },
                    '& .MuiFormLabel-root': {
                      fontFamily: 'Nunito',
                      fontWeight: 'bold',
                      paddingLeft: '8px',
                      borderColor: 'red'
                    },
                    '& label.Mui-focused': {
                      color: '#5A4FA2'
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#f6f6f6',
                      },
                      '&:hover fieldset': {
                        borderColor: '#f6f6f6',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#f6f6f6',
                      },
                    },
                    backgroundColor: '#f6f6f6', 
                    fontFamily: 'Nunito',
                    borderRadius: '100px',
                    boxShadow: '0px 8px 24px 1px rgba(112, 144, 176, .15)'
                  }}
                  {...params} 
                  label="Find your school" 
                  />}>
          </Autocomplete>
        </div>
      </div>
      
      
    </div>
  );
}

export default Home;