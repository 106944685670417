import React from "react";
//import Main from "./Main.js";
import Home from "./Redesign/Home.jsx";
import Main from "./Redesign/Main.jsx";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import 'firebase/firestore'

export default function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/UGA" element={<Main school={{ value: 'UGA', label: 'University of Georgia', id: 'university_of_georgia' }}></Main>}>
          </Route>
          <Route path="/KSU" element={<Main school={{ value: 'KSU', label: 'Kennesaw State University', id: 'kennesaw_state_university' }}></Main>}>
          </Route>
          {/* <Route path="/" element={<Welcome/>}>
          </Route> */}
          {/* <Route path="/home" element={<Home/>}>
          </Route> */}
          <Route exact path="/" element={<Home/>}>
          </Route>
        </Routes>
      </div>
    </Router>
  );
}