export const INCREMENT = 'INCREMENT';

export const DECREMENT = 'DECREMENT';

export const FETCH_LIST_OF_CLASSES = 'FETCH_LIST_OF_CLASSES';

export const SET_SCHOOL = 'SET_SCHOOL';

export const SET_PREFERENCES = 'SET_PREFERENCES';

export const SET_CLASSES = 'SET_CLASSES';

export const SET_SECTIONS = 'SET_SECTIONS';

export const SET_BLOCKS = 'SET_BLOCKS';

export const GET_CLASS_BY_ID = 'GET_CLASS_BY_ID';

export const SET_CLASS_COLORS = 'SET_CLASS_COLORS';

export const SET_SHADOW_SECTION = 'SET_SHADOW_SECTION';

export const SET_SMART_SCHEDULER = 'SET_SMART_SCHEDULER';