import { initializeApp } from "firebase/app";
import {collection, doc, setDoc, getDoc, getDocs, getFirestore, updateDoc, addDoc} from 'firebase/firestore';
import {getAuth} from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyC7tyAbiwoukp7HLBUWgoTi7h09WMIrTRo",
    authDomain: "coursebuddy-cb690.firebaseapp.com",
    projectId: "coursebuddy-cb690",
    storageBucket: "coursebuddy-cb690.appspot.com",
    messagingSenderId: "189320751959",
    appId: "1:189320751959:web:ebd85d20bba0c6d89f6c12",
    measurementId: "G-NSH4W078G2"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const database = getFirestore(app);
export {auth};
export {database};

export const createUser = async (userId, data) => {
    await setDoc(doc(database, "userData", userId), data);
}

export const sendFeedbackToDB  = async (satisfaction, feedbackText) => {
    let data = {
        feedback: feedbackText,
        satisfaction: satisfaction
    };

    let collRef = await collection( database , "feedback");
    await addDoc( collRef , data );
}

export const getAllUserData = async () => {
    const usersCollectionRef = collection(database, "userData");
    const data = await getDocs(usersCollectionRef);
    data.docs.map((doc) => {
        return doc.data();
    })
}

export const getAllClassData = async () => {
    const classCollectionRef = collection(database, "UGA");
    const data = await getDocs(classCollectionRef);
    const classData = [];
    data.docs.map((doc) => {
        return classData.push(doc.data());
    })
    return classData;
}

export const getClassData = async (schoolId, classId) => {
    const docRef = doc(database, schoolId, classId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        //console.log(docSnap.data());
        return docSnap.data();
    } else {
        //console.log("No such document!");
        return {};
    }
}

export const getClassNameData = async () => {
    const docRef = doc(database, "UGA", "classNames");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        //console.log(docSnap.data());
        return docSnap.data();
    } else {
        //console.log("No such document!");
        return {};
    }
}

export const getListOfClasses = async (id) => {
    const docRef = doc(database, id, "classNames");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        //console.log(docSnap.data());
        return docSnap.data();
    } else {
        //console.log("No such document!");
        return {};
    }
}

export const getUserData = async (userId) => {
    const docRef = doc(database, "userData", userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        //console.log(docSnap.data());
        return docSnap.data();
    } else {
        //console.log("No such document!");
        return {};
    }
}

export const updateUser = async (userId, data) => {
    const userDoc = doc(database, "userData", userId);
    const newData = data;
    await updateDoc(userDoc, newData);
}