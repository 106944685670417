import { 
    INCREMENT,
    DECREMENT,
    FETCH_LIST_OF_CLASSES,
    SET_SCHOOL,
    SET_PREFERENCES,
    SET_CLASSES,
    SET_SECTIONS,
    SET_BLOCKS,
    GET_CLASS_BY_ID,
    SET_CLASS_COLORS,
    SET_SHADOW_SECTION,
    SET_SMART_SCHEDULER
} from './config.types';

import {
    getClassData,
    getClassNameData,
    getAllClassData,
    getListOfClasses,
} from '../../firebaseData';

export const increaseCounter = () => {

    return {

        type: INCREMENT,

    };

};

export const decreaseCounter = () => {

    return {

        type: DECREMENT,

    };

};

export function fetchListOfClasses(id) {
    return function(dispatch) {
        return getListOfClasses(id).then(classList => {
            dispatch({
                    type: FETCH_LIST_OF_CLASSES,
                    id: id,
                    classList: classList
            });
        });
    };
};

export const setSchool = (school) => {

    return {
        type: SET_SCHOOL,
        school: school
    };

};

export const setStatePreferences = (preferences) => {

    return {
        type: SET_PREFERENCES,
        preferences: preferences
    };

};

export const setStateClasses = (classes) => {

    return {
        type: SET_CLASSES,
        classes: classes
    };

};

export const setStateSections = (sections) => {

    return {
        type: SET_SECTIONS,
        sections: sections
    };

};

export const setStateBlocks = (blocks) => {

    return {
        type: SET_BLOCKS,
        blocks: blocks
    };

};

export const setStateClassColors = (classColors) => {

    return {
        type: SET_CLASS_COLORS,
        classColors: classColors
    };

};

export const getClassById = (schoolId, classId) => {
    return function(dispatch) {
        return getClassData(schoolId, classId).then(classData => {
            dispatch({
                    type: GET_CLASS_BY_ID,
                    schoolId: schoolId,
                    classId: classId,
                    classData: classData
            });
        });
    };
};

export const setStateShadowSection = (shadowSection) => {

    return {
        type: SET_SHADOW_SECTION,
        shadowSection: shadowSection
    };

};

export const setStateSmartScheduler = (smartScheduler) => {

    return {
        type: SET_SMART_SCHEDULER,
        smartScheduler: smartScheduler
    };

};

