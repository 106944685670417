import {React, useEffect, useState} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { render } from "react-dom";
import { motion } from "framer-motion";
import _ from 'lodash';
import {
  increaseCounter,
  decreaseCounter,
  fetchListOfClasses,
  setStatePreferences,
  setStateClasses,
  setStateSections,
  setStateBlocks,
  getClassById,
  setStateClassColors,
  setStateShadowSection,
  setStateSmartScheduler
} from "../redux/Config/config.actions";

import '../App.css';

import { styled } from '@mui/material/styles';
import {
  Autocomplete,
  TextField,
  Box,
  Popper,
  ButtonGroup,
  Button,
  Grid,
  IconButton,
  Collapse,
  List,
  ListItem,
  Card,
  CardHeader,
  CardContent,
  Typography,
  CardActions,
  Switch
} from "@mui/material";

import DownIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import UpIcon from '@mui/icons-material/ExpandLessRounded';
import FilterIcon from '@mui/icons-material/TuneOutlined';
import DeleteIcon from '@mui/icons-material/RemoveCircleOutlineRounded';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
  })(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));


function convertTime(rawTime) {
  let h = parseInt(rawTime/60);
  let m = rawTime%60;
  let hour = h===12 ? h : h%12;
  let minute = m>=10 ? m : `0${m}`;

  let xm = rawTime>=720 ? 'pm' : 'am';
  return `${hour}:${minute}${xm}`;
}

function ClassCard(props) {
  const dispatch = useDispatch();
  const stateSections = useSelector((state) => state.config.sections);
  const stateClasses = useSelector((state) => state.config.classes);
  const stateShadowSection = useSelector((state) => state.config.shadowSection);
  const [c, setC] = useState(props.c);
  const [expanded, setExpanded] = useState(c.expanded);

  const handleExpand = () => {
    setExpanded(!expanded);
  }

  const renderDayTimes = (section) => {
    let dayTimes = [];
    let blocks = section.blocks;
    blocks.forEach(block => {
      let isNewDayTime = true;
      let xIndex = null;
      dayTimes.forEach((dayTime, i) => {
        if (dayTime.startTime && dayTime.endTime && block.startTime && block.endTime && dayTime.startTime === block.startTime && dayTime.endTime === block.endTime) {
          isNewDayTime = false;
          xIndex = i;
        }
      });
      if (isNewDayTime) {
        let days = [];
        days.push(block.day);
        let dayTime = {
          startTime: block.startTime,
          endTime: block.endTime,
          days: days
        }
        dayTimes.push(dayTime);
      } else {
        let tempDays = dayTimes[xIndex].days;
        tempDays.push(block.day);
        dayTimes[xIndex].days = tempDays;
      }
    });
    return (
      <div>
        {dayTimes.map((dayTime, i) => {
          return (
            <div style={{
              display: 'flex',
              margin: '4px 0px'
            }}>
              <div style={{marginRight: '3px', border: '3px solid #898484', borderRadius: '4px', width: '10px', height: '10px', backgroundColor: dayTime.days.includes('M') ? c.classColor : '#EBEBEB'}}></div>
              <div style={{marginRight: '3px', border: '3px solid #898484', borderRadius: '4px', width: '10px', height: '10px', backgroundColor: dayTime.days.includes('T') ? c.classColor : '#EBEBEB'}}></div>
              <div style={{marginRight: '3px', border: '3px solid #898484', borderRadius: '4px', width: '10px', height: '10px', backgroundColor: dayTime.days.includes('W') ? c.classColor : '#EBEBEB'}}></div>
              <div style={{marginRight: '3px', border: '3px solid #898484', borderRadius: '4px', width: '10px', height: '10px', backgroundColor: dayTime.days.includes('R') ? c.classColor : '#EBEBEB'}}></div>
              <div style={{border: '3px solid #898484', borderRadius: '4px', width: '10px', height: '10px', backgroundColor: dayTime.days.includes('F') ? c.classColor : '#EBEBEB'}}></div>
              <span style={{marginLeft: '10px'}}>{`${convertTime(dayTime.startTime)} - ${convertTime(dayTime.endTime)}`}</span>
            </div>
          )
        })}
      </div>
      
    )
  }

  const handleSelectSection = (section, i) => {
    //update local sections c.array
    //update redux state
    //include the class color with the section
    let classData = _.cloneDeep(c);
    if (classData.sections && classData.sections.length > 0) {
      classData.sections[i].selected = true;
      dispatch(setStateShadowSection(null));
    }
    setC(classData);
    let sections = stateSections && stateSections.length>0 ? stateSections : [];
    let tempSection = section;
    tempSection.classColor = c.classColor;
    sections.push(tempSection);
    dispatch(setStateSections(sections));

    let classes = stateClasses && stateClasses.length>0 ? _.cloneDeep(stateClasses) : [];
    for (let j=0; j<classes.length; j++) {
      if (classes[j].courseNumber === c.courseNumber && classes[j].subject === c.subject && classes[j].sections) {
        classes[j].sections[i].selected = true;
        j=classes.length*2;
      }
    }
    dispatch(setStateClasses(classes));
  }

  const handleDeselectSection = (section, i) => {
    //update local sections c.array
    //update redux state
    let classData = _.cloneDeep(c);
    if (classData.sections && classData.sections.length > 0) {
      classData.sections[i].selected = false;
    }
    setC(classData);
    let sections = _.cloneDeep(stateSections);
    for (let j=0; j<sections.length; j++) {
      if (sections[j].crn === section.crn) {
        sections.splice(j, 1);
        j=sections.length*2;
      }
    }
    dispatch(setStateSections(sections));

    let classes = stateClasses && stateClasses.length>0 ? _.cloneDeep(stateClasses) : [];
    for (let j=0; j<classes.length; j++) {
      if (classes[j].courseNumber === c.courseNumber && classes[j].subject === c.subject && classes[j].sections) {
        classes[j].sections[i].selected = false;
        j=classes.length*2;
      }
    }
    dispatch(setStateClasses(classes));
  }

  const onSectionHover = (section) => {
    if (!section.selected && !stateShadowSection) {
      let tempSection = section;
      tempSection.classColor = c.classColor;
      tempSection.isShadowSection = true;
      dispatch(setStateShadowSection(tempSection));
    }
  }

  const onSectionStopHover = (section) => {
    dispatch(setStateShadowSection(null));
  }

  const renderSection = (section, i) => {

    const sectionVariants = {
      selected: {
        backgroundColor: ["#EBEBEB", c.classColor],
        color: ["#898484", "#FFFFFF"],
        transition: {
          //delay: 1,
          duration: .25,
          //ease: [0.075, 0.82, 0.165, 1],
          //repeat: Infinity,
          //repeatType: "reverse"
        }
      },
      deselected: {
        backgroundColor: [c.classColor, "#EBEBEB"],
        color: ["#FFFFFF", "#898484"],
        transition: {
          //delay: 1,
          duration: .25,
          //ease: [0.075, 0.82, 0.165, 1],
          //repeat: Infinity,
          //repeatType: "reverse"
        }
      },
      initial: {
        backgroundColor: '#EBEBEB'
      }
    };

    return (
      <motion.div
        onMouseOver={() => onSectionHover(section)}
        onMouseLeave={() => onSectionStopHover(section)}
        whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.9 }}
        variants={sectionVariants}
        animate={section.selected ? 'selected' : 'deselected'}
        initial='initial'
        style={{
          width: '90%',
          backgroundColor: '#EBEBEB',
          minHeight: '72px',
          borderRadius: '12px',
          padding: '8px 16px',
          boxSizing: 'border-box',
          color: '#898484',
          boxShadow: '0px 8px 24px 1px rgba(112, 144, 176, .55)',
          margin: '6px 0px',
          display: 'flex',
          flexDirection: 'column',
          fontSize: '14px',
          cursor: 'pointer'
        }}
        onClick={() => {section.selected ? handleDeselectSection(section, i) : handleSelectSection(section, i)}}
      >
        <span style={{marginBottom: '4px'}}>
          {section.instructor}
        </span>
        <span>
          {renderDayTimes(section)}
        </span>
        <span style={{fontSize: '12px'}}>
          {`crn: ${section.crn}`}
        </span>
      </motion.div>
    )
  }

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.8,
        ease: [0, 0.71, 0.2, 1.01]
      }}
      style={{
        width: '100%',
        backgroundColor: c.classColor,
        borderRadius: '15px',
        boxShadow: '0px 8px 24px 1px rgba(112, 144, 176, .25)',
        color: 'white',
        fontSize: 'calc(12px + .5vw)',
        fontWeight: '900',
        boxSizing: 'border-box',
        marginTop: '12px',
        overflow: 'hidden'
      }}
    >
      <div style={{padding: '0px 18px'}}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: '8px 0px'
          }}
        >
          <span>{`${c.subject} ${c.courseNumber}`}</span>
          <div>
            <ExpandMore
              expand={expanded}
              onClick={handleExpand}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <DownIcon fontSize="medium" style={{color: "white"}}></DownIcon>
            </ExpandMore>
          </div>
        </div>
      </div>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
          <div
            style={{
              width: '100%',
              backgroundColor: 'rgba(255,255,255,.5)',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '12px 0px'
            }}
          >
            {c.sections.map((section, i) => {
              return renderSection(section, i);
            })}
          </div>
          <div
            style={{
              width: '100%',
              height: '42px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              padding: '0px 8px',
              boxSizing: 'border-box'
            }}
          >
            <IconButton onClick={props.deleteClass}><DeleteIcon style={{color: 'white'}}></DeleteIcon></IconButton>
          </div>

      </Collapse>
    </motion.div>

  )
}

function Main(props) {

  const dispatch = useDispatch();
  const school = props.school;

  const classList = useSelector((state) => state.config.classList);

  const requestedClassData = useSelector((state) => state.config.requestedClassData);

  const stateShadowSection = useSelector((state) => state.config.shadowSection);

  const stateClasses = useSelector((state) => state.config.classes);
  const stateSections = useSelector((state) => state.config.sections);

  const stateClassColors = useSelector((state) => state.config.classColors);

  const stateSmartScheduler = useSelector((state) => state.config.smartScheduler);
  const stateSmartSchedulerEnabled = useSelector((state) => state.config.smartScheduler.enabled);

  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [searchValue, setSearchValue] = useState();
  const [blocks, setBlocks] = useState([]);
  const [classColors, setClassColors] = useState([
    '#5A4FA2',
    '#8800C7',
    '#00ABC1',
    '#3954EC',
    '#F1407E',
    '#F28C29',
    '#77DD77'
  ]);

  useEffect( () => {
    getListOfClasses();
  }, []);

  useEffect( () => {
    if (requestedClassData !== null && requestedClassData !== undefined) {
      addClass(requestedClassData);
    }
  }, [requestedClassData]);

  // useEffect( () => {
    
  //   if (stateClasses) {
  //     setClasses(stateClasses);
  //   }
  // }, [stateClasses]);


  const addClass = (classData) => {
    let newClasses = _.cloneDeep(classes);

    let isNewClass = true;
    newClasses.forEach(c => {
      if (c.courseNumber === classData.courseNumber && c.subject === classData.subject) {
        isNewClass = false;
      }
    });
    if (isNewClass) {
      let tempClassData = classData;
      tempClassData.classColor = getClassColor();
      newClasses.unshift(tempClassData);
      setClasses(newClasses);
      dispatch(setStateClasses(newClasses));
    }
  };

  const deleteClass = (index, c) => {
    let tempClassColors = stateClassColors;
    tempClassColors.push(c.classColor);
    dispatch(setStateClassColors(classColors));
    let newClasses = _.cloneDeep(stateClasses);
    let classToDelete = newClasses[index];
    let newSections = stateSections && stateSections.length > 0 ? _.cloneDeep(stateSections): [];

    for (let i=0; i<newSections.length; i++) {
      if (newSections[i] && newSections[i].subject === classToDelete.subject && newSections[i].courseNumber === classToDelete.courseNumber) {
        newSections.splice(i, 1);
        i--;
      }
    }

    newClasses.splice(index, 1);

    setClasses(newClasses);
    dispatch(setStateClasses(newClasses));
    dispatch(setStateSections(newSections));
  };

  const addSection = (sectionData, classColor) => {
    let newSections = _.cloneDeep(sections);
    let isNewSection = true;
    newSections.forEach(s => {
      if (s.crn === sectionData.crn) {
        isNewSection = false;
      }
    });
    if (isNewSection) {
      let tempSectionData = sectionData;
      tempSectionData.classColor = classColor;
      newSections.push(tempSectionData);
      
      setSections(newSections);
      dispatch(setStateSections(newSections));
    }
  };

  const deleteSection = (index) => {
    let newSections = _.cloneDeep(sections);

    newSections.splice(index, 1);

    setSections(newSections);
    dispatch(setStateSections(newSections));
  };

  const expandClass = (index) => {
    let newClasses = _.cloneDeep(classes);

    newClasses[index].expanded = true;

    setClasses(newClasses);
    dispatch(setStateClasses(newClasses));
  };

  const minimizeClass = (index) => {
    let newClasses = _.cloneDeep(classes);

    newClasses[index].expanded = false;

    setClasses(newClasses);
    dispatch(setStateClasses(newClasses));
  };

  const getClassColor = () => {
    let tempClassColors = stateClassColors && stateClassColors.length > 0 ? stateClassColors : [
      '#5A4FA2',
      '#00ABC1',
      '#3954EC',
      '#F1407E',
      '#F28C29',
      '#77DD77',
      '#8800C7'
  ];
    let classColor = tempClassColors.shift();
    setClassColors(tempClassColors);
    dispatch(setStateClassColors(tempClassColors));
    return classColor;
  };

  const handleIncrement = () => {
    dispatch(increaseCounter());
  };

  const handleDecrement = () => {
    dispatch(decreaseCounter());
  }

  const getListOfClasses = () => {
    dispatch(fetchListOfClasses(school.id));
  }

  const handleClassSelected = (value) => {
    if (value && value.id) {
      if (requestedClassData && value.id === requestedClassData.subject + " " + requestedClassData.courseNumber) {
        addClass(requestedClassData);
        setSearchValue();
      }
      dispatch(getClassById(school.id, value.id));
    }
  }

  const isConflict = (s1, e1, s2, e2) => {
    if ((s1<=e2) && (e1>=s2)) {
      return true;
    } else {
      return false;
    }
  }

  const renderBlock = (block) => {
    let height = block.endTime-block.startTime;
    let top = block.startTime-480;
    let groupSize = block.groupData && block.groupData.size ? block.groupData.size : 1;
    let groupPosition = block.groupData && block.groupData.position ? block.groupData.position : 0;
    return (
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: block.isShadowSection ? 0.5 : 1, scale: 1 }}
        transition={{
          duration: 0.8,
          ease: [0, 0.71, 0.2, 1.01]
        }}
        style={{
          height: (height / 600)*100 + '%',
          top: (top / 600)*100 + '%',
          position: 'absolute',
          backgroundColor: block.classColor ? block.classColor : 'grey',
          borderRadius: '10px',
          boxShadow: '0px 8px 24px 4px rgba(112, 144, 176, .25)',
          padding: 'calc(4px + .2vh) 1.5vh',
          boxSizing: 'border-box',
          color: 'white',
          fontSize: 'calc(12px + .5vh)',
          fontWeight: '900',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          marginLeft: (5+(groupPosition*(90/groupSize)))+'%',
          width: (90/groupSize)+'%'
        }}
        key={`${block.startTime}-${block.endTime}-${block.day}-${block.subject}-${block.courseNumber}-${block.crn}`}
      >
        <div>{block.subject}</div>
        <div style={{color: '#E6E6E6', fontSize: 'calc(10px + .5vh)', marginTop: '-5px'}}>{block.courseNumber}</div>
      </motion.div>
    );
  }

  const setConflictGroups = (dayBlocks) => {
    let m = _.cloneDeep(dayBlocks);
    let groups = [];
    let s;
    let e;
    for (let i=0; i<m.length-1; i++) {
      if (!s && !e) {
        s = m[i].startTime;
        e = m[i].endTime;
      }

      let group = [];
      group.push(i);
      while (m[i+1] && isConflict(s, e, m[i+1].startTime, m[i+1].endTime)) {
        group.push(i+1);
        e = e > m[i+1].endTime ? e : m[i+1].endTime;
        i++;
      }
      s = null;
      e = null;
      groups.push(group);
    }
    
    groups.forEach(group => {
      group.forEach((index, i) => {
        m[index].groupData = {
          position: i,
          size: group.length
        }
      });
    });
    return m;
  }

  const renderCalendar = () => {
    let sections = stateSections && stateSections.length>0 ? stateSections : [];
    let tempShadowSectionArr = [];
    if (stateShadowSection) {
      let tempShadowSection = stateShadowSection;
      tempShadowSectionArr.push(tempShadowSection);
    }
    let m = [];
    let t = [];
    let w = [];
    let r = [];
    let f = [];
    sections.forEach(section => {
      section.isShadowSection = false;
    })
    sections.concat(tempShadowSectionArr).forEach((section, index) => {
      if (section.blocks && section.blocks.length > 0) {
        section.blocks.forEach(block => {
          let blockData = block;
          blockData.subject = section.subject;
          blockData.courseNumber = section.courseNumber;
          blockData.classColor = section.classColor;
          blockData.crn = section.crn;
          blockData.isShadowSection = section.isShadowSection;
          switch(block.day.toLowerCase()) {
            case 'm': {
              m.push(blockData);
              break;
            }
            case 't': {
              t.push(blockData);
              break;
            }
            case 'w': {
              w.push(blockData);
              break;
            }
            case 'r': {
              r.push(blockData);
              break;
            }
            case 'f': {
              f.push(blockData);
              break;
            }
            default: {
              break;
            }
          }
        });
      }
    });
    m.sort((a, b) => a.startTime - b.startTime);
    t.sort((a, b) => a.startTime - b.startTime);
    w.sort((a, b) => a.startTime - b.startTime);
    r.sort((a, b) => a.startTime - b.startTime);
    f.sort((a, b) => a.startTime - b.startTime);

    m = setConflictGroups(m);
    t = setConflictGroups(t);
    w = setConflictGroups(w);
    r = setConflictGroups(r);
    f = setConflictGroups(f);

    return (
      <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', fontWeight: 'bolder' }}>
        <div style={{ flexGrow: '1', height: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }}>
          {m.map((block) => {
            return renderBlock(block);
          })}
        </div>
        <div style={{ flexGrow: '1', height: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }}>
          {t.map((block) => {
            return renderBlock(block);
          })}
        </div>
        <div style={{ flexGrow: '1', height: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }}>
          {w.map((block) => {
            return renderBlock(block);
          })}
        </div>
        <div style={{ flexGrow: '1', height: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }}>
          {r.map((block) => {
            return renderBlock(block);
          })}
        </div>
        <div style={{ flexGrow: '1', height: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }}>
          {f.map((block) => {
            return renderBlock(block);
          })}
        </div>
      </div>
    );
  }

  const renderClasses = () => {
    return (
      <div
        class="classesContainer"
        style={{
          width: '100%',
          height: '100%',
          flexGrow: '1',
          overflowX: 'visible',
          padding: '0 10px',
          margin: '0px -32px',
          padding: '0px 32px'
        }}
      >
        {classes.map((c, index) => {
          return (
            <ClassCard
              key={c.courseTitle}
              c={c}
              deleteClass={() => deleteClass(index, c)}
              addSection={addSection}
              deleteSection={deleteSection}
            >

            </ClassCard>
          );
        })}
      </div>
    )
  }

  const handleToggleSmartScheduler = () => {
    let enabled = stateSmartSchedulerEnabled;
    let tempSmartScheduler = stateSmartScheduler ? stateSmartScheduler : {};
    tempSmartScheduler.enabled = !enabled;
    dispatch(setStateSmartScheduler(tempSmartScheduler));
  }

  const spring = {
    type: "spring",
    stiffness: 700,
    damping: 30
  };

  return (
    <div style={{ height: '100vh', maxHeight: '100vh', width: '100vw', backgroundColor: '#EBEBEB', fontFamily: 'Nunito', overflow: 'hidden', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

      {/* Header */}
      <div
        style={{
          width: '99%',
          minHeight: '60px',
          backgroundColor: '#F6F6F6',
          borderRadius: '0 0 30px 30px',
          boxShadow: '0px 8px 24px 1px rgba(112, 144, 176, .15)',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0px 32px',
          boxSizing: 'border-box'
        }}
      >
        <span style={{fontSize: 'calc(24px + .5vh)', fontWeight: '900', color: '#898484', fontStyle: 'italic'}}>
            Course<span style={{color: '#5A4FA2'}}>Buddy</span>
        </span>
      </div>

      {/* Main Dashboard Container */}
      <div style={{
        width: '90%',
        height: '90%',
        backgroundColor: '#F6F6F6',
        borderRadius: '35px',
        boxShadow: '0px 8px 24px 1px rgba(112, 144, 176, .15)',
        marginTop: '16px',
        marginBottom: '16px',
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '40px 40px',
        boxSizing: 'border-box'
      }}>

        {/* Container for Hours and Calendar and Class Dashboard*/}
        <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'flex-end' }}>

          {/* Container for Hours */}
          <div
            style={{
              width: '50px',
              height: '90%',
              display: 'flex',
              flexDirection: 'column',
              boxSizing: 'border-box',
              color: '#969696',
              fontSize: 'calc(16px + .5vw)',
              marginRight: '4px'
            }}
          >
            <div style={{ height: '100%', width: '100%', fontWeight: '800' }}>
              <div style={{ height: '100%', width: '100%', position: 'relative', display: 'flex', bottom: '50%', justifyContent: 'center', alignItems: 'center' }}>
                8
              </div>
            </div>
            <div style={{ height: '100%', width: '100%', fontWeight: '800' }}>
              <div style={{ height: '100%', width: '100%', position: 'relative', display: 'flex', bottom: '50%', justifyContent: 'center', alignItems: 'center' }}>
                9
              </div>
            </div>
            <div style={{ height: '100%', width: '100%', fontWeight: '800' }}>
              <div style={{ height: '100%', width: '100%', position: 'relative', display: 'flex', bottom: '50%', justifyContent: 'center', alignItems: 'center' }}>
                10
              </div>
            </div>
            <div style={{ height: '100%', width: '100%', fontWeight: '800' }}>
              <div style={{ height: '100%', width: '100%', position: 'relative', display: 'flex', bottom: '50%', justifyContent: 'center', alignItems: 'center' }}>
                11
              </div>
            </div>
            <div style={{ height: '100%', width: '100%', fontWeight: '800' }}>
              <div style={{ height: '100%', width: '100%', position: 'relative', display: 'flex', bottom: '50%', justifyContent: 'center', alignItems: 'center' }}>
                12
              </div>
            </div>
            <div style={{ height: '100%', width: '100%', fontWeight: '800' }}>
              <div style={{ height: '100%', width: '100%', position: 'relative', display: 'flex', bottom: '50%', justifyContent: 'center', alignItems: 'center' }}>
                1
              </div>
            </div>
            <div style={{ height: '100%', width: '100%', fontWeight: '800' }}>
              <div style={{ height: '100%', width: '100%', position: 'relative', display: 'flex', bottom: '50%', justifyContent: 'center', alignItems: 'center' }}>
                2
              </div>
            </div>
            <div style={{ height: '100%', width: '100%', fontWeight: '800' }}>
              <div style={{ height: '100%', width: '100%', position: 'relative', display: 'flex', bottom: '50%', justifyContent: 'center', alignItems: 'center' }}>
                3
              </div>
            </div>
            <div style={{ height: '100%', width: '100%', fontWeight: '800' }}>
              <div style={{ height: '100%', width: '100%', position: 'relative', display: 'flex', bottom: '50%', justifyContent: 'center', alignItems: 'center' }}>
                4
              </div>
            </div>
            <div style={{ height: '100%', width: '100%', fontWeight: '800' }}>
              <div style={{ height: '100%', width: '100%', position: 'relative', display: 'flex', bottom: '50%', justifyContent: 'center', alignItems: 'center' }}>
                5
              </div>
            </div>
          </div>

          {/* Grey Calendar Container */}
          <div
            style={{
              flexGrow: '3',
              height: '100%',
              backgroundColor: '#D9D9D9',
              borderRadius: '15px',
              boxShadow: '0px 8px 24px 1px rgba(112, 144, 176, .25)',
              padding: '0px 8px',
              display: 'flex',
              flexDirection: 'column',
              position: 'relative'
            }}
          >
            <div style={{ width: '100%', height: '10%', display: 'flex', alignItems: 'center', fontWeight: '800', color: '#898484', fontSize: 'calc(16px + .5vw)' }}>
              <div style={{ flexGrow: '1', textAlign: 'center', height: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>M</div>
              <div style={{ flexGrow: '1', textAlign: 'center', height: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>T</div>
              <div style={{ flexGrow: '1', textAlign: 'center', height: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>W</div>
              <div style={{ flexGrow: '1', textAlign: 'center', height: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>R</div>
              <div style={{ flexGrow: '1', textAlign: 'center', height: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>F</div>
            </div>
            <div style={{ width: '100%', height: '90%' }}>
              {renderCalendar()}
            </div>

            {/* smart scheduler */}
            {/* <div 
              style={{
                position: 'absolute',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                left: '0px',
                bottom: '0px',
                padding: '16px 16px',
                boxSizing: 'border-box'
              }}
            >
              <div 
                style={{
                  width: '160px',
                  height: '70px',
                  background: 'rgba(84, 69, 255, .6)',
                  boxShadow: '0px 8px 24px 1px rgba(112, 144, 176, .50)',
                  borderRadius: '16px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '16px 16px',
                  boxSizing: 'border-box',
                  color: 'white',
                  fontWeight: '900',
                  letterSpacing: '-.005em',
                  lineHeight: '16px'
                }}
                onClick={() => handleToggleSmartScheduler()}
              >
                <span>Smart Scheduler</span>
                <Switch
                  checked={stateSmartSchedulerEnabled}
                  //onChange={() => handleToggleSmartScheduler()}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </div>
            </div> */}
            
          </div>

          {/* Class Dashboard */}
          <div
            style={{
              //flexGrow: '1',
              height: '100%',
              maxHeight: '100%',
              width: '35%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flexStart',
              padding: '0 0 0 40px',
              boxSizing: 'border-box'
              }}
          >

            {/* Search Bar */}
            <div style={{display: 'flex', width: '100%'}}>
              <Autocomplete
                options={classList && classList.length > 0 ? classList : []}
                sx={{
                  width: '100%',
                }}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <div style={{width: '100%', height: '100%'}} onClick={()=>{handleClassSelected(option)}} component="li"  {...props}>
                    <motion.div
                      whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.9 }}
                      style={{
                        width: '100%',
                        height: '100%',
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        backgroundColor: '#EBEBEB',
                        padding: '16px',
                        borderRadius: '10px'}} 
                        onClick={()=>{handleClassSelected(option)}}>{option.name}</motion.div>
                  </div>
                )}
                renderInput={(params) =>
                    <TextField
                      value={searchValue}
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                      }}
                      sx={{
                        '& .MuiInputBase-root': {
                          borderRadius: '100px',
                          fontFamily: 'Nunito',
                          fontWeight: '900',
                          paddingLeft: '16px',
                          boxSizing: 'border-box',
                          color: '#898484'
                        },
                        '& .MuiInputBase-input': {
                          fontFamily: 'Nunito',
                          fontWeight: '900',
                          paddingLeft: '8px',
                        },
                        '& .MuiFormLabel-root': {
                          fontFamily: 'Nunito',
                          fontWeight: '900',

                          borderColor: '#D9D9D9',
                          color: '#969696'
                        },
                        '& label.Mui-focused': {
                          color: '#969696'
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#D9D9D9',
                          },
                          '&:hover fieldset': {
                            borderColor: '#D9D9D9',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#D9D9D9',
                          },
                        },
                        backgroundColor: '#D9D9D9',
                        fontFamily: 'Nunito',
                        fontWeight: '900',
                        borderRadius: '100px',
                        boxShadow: '0px 8px 24px 1px rgba(112, 144, 176, .15)'
                      }}
                      {...params}
                      label="Search for a class..."
                      />}>
              </Autocomplete>
              {/* <motion.div 
                style={{
                  display: 'flex',
                  height: '100%',
                  width: '60px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: 'rgba(84, 69, 255, .6)',
                  marginLeft: '8px',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  boxShadow: '0px 8px 24px 1px rgba(112, 144, 176, .25)',
                }}
                whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.9 }}
              >
                <FilterIcon style={{color: 'white'}}></FilterIcon>
              </motion.div> */}
            </div>
            
            {/* classes clicked on */}
            {renderClasses()}
          </div>

        </div>
      </div>

    </div>
  );
}

export default Main;